import { useEffect, useState } from "react";
import { API_BASE_URL } from "../../helpers/base_url";
import './header.css'
import logo from '../../imgs/logos/cadland-logo.png'

function Header() {
  const [estates, setEstates] = useState([])
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    fetch(`${API_BASE_URL}/estate`)
      .then(res => res.json())
      .then(data => {
        console.log('Parsed data:', data);
        setEstates(data?.data?.estates);
      })
      .catch(err => console.error('Fetch error:', err));
  }, []);

  const toggleDropdown = (e: any) => {
    e.preventDefault();
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    // <header
    //   className="transparent-header nav-on-banner fixed-top fixed-bg-white"
    //   style={{ backgroundColor: "#fff" }}
    // >
    //   <div className="main-nav py-2 xs-p-0">
    //     <div className="container">
    //       <p className="motto">FOR | THE | FUTURE</p>
    //       <div className="row">
    //         <div className="col">
    //           <nav className="navbar navbar-expand-lg nav-black nav-primary-hover nav-line-active">
    //             <a className="navbar-brand" href="/">
    //               <img
    //                 className="nav-logo"
    //                 src={logo}
    //                 alt="Image not found !"
    //               />
    //             </a>
    //             <button
    //               className="navbar-toggler"
    //               type="button"
    //               data-bs-toggle="collapse"
    //               data-bs-target="#navbarSupportedContent"
    //               aria-controls="navbarSupportedContent"
    //               aria-expanded="false"
    //               aria-label="Toggle navigation"
    //             >
    //               <span className="navbar-toggler-icon flaticon-menu flat-small text-primary"></span>
    //             </button>
    //             <div
    //               className="collapse navbar-collapse menu-items-container"
    //               id="navbarSupportedContent"
    //             >
    //               <ul className="navbar-nav ms-auto">
    //                 <li className="nav-item">
    //                   <a className="nav-link nav-link-style" href="/">
    //                     Home
    //                   </a>
    //                 </li>
    //                 <li className="nav-item">
    //                   <a className="nav-link nav-link-style" href="#contact-us">
    //                     Contact Us
    //                   </a>
    //                 </li>
    //                 <li className="nav-item">
    //                   <a className="nav-link nav-link-style" href="#gallery">
    //                     Gallery
    //                   </a>
    //                 </li>
    //                 <li className="nav-item dropdown">
    //                   <a
    //                     className="nav-link nav-link-style dropdown-toggle"
    //                     href="#"
    //                     onClick={toggleDropdown}
    //                   >
    //                     Our estates
    //                   </a>
    //                   <ul className={`dropdown-menu ${isDropdownOpen ? 'show' : ''}`}>
    //                     {estates.map((estate: any, index) => (
    //                       <li key={estate.id || index}>
    //                         <a className="nav-item-list dropdown-item" href={`/estates/${estate.id}`}>
    //                           {estate.name || 'Estate'}
    //                         </a>
    //                       </li>
    //                     ))}
    //                   </ul>
    //                 </li>
    //               </ul>
    //               <div className="nav-btn-container">
    //                 <a
    //                   href="/affiliate-program"
    //                   className="btn btn-white add-listing-btn nav-link-style become-an-affiliate-btn"
    //                 >
    //                   Become an affiliate
    //                 </a>
    //                 <a
    //                   href="/buy-land"
    //                   className="btn btn-white add-listing-btn nav-link-style buy-now-btn"
    //                 >
    //                   Buy Now
    //                 </a>
    //               </div>
    //             </div>
    //           </nav>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </header>

    <header
      className="transparent-header nav-on-banner fixed-top fixed-bg-white w-100"
      style={{
        backgroundColor: "#fff",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1030
      }}
    >
      <div className="main-nav py-2 xs-p-0">
        <div className="container">
          <p className="motto">FOR | THE | FUTURE</p>
          <div className="row">
            <div className="col">
              <nav className="navbar navbar-expand-lg nav-black nav-primary-hover nav-line-active">
                <a className="navbar-brand" href="/">
                  <img className="nav-logo" src={logo} alt="Image not found !" />
                </a>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon flaticon-menu flat-small text-primary"></span>
                </button>
                <div
                  className="collapse navbar-collapse menu-items-container"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav ms-auto">
                    <li className="nav-item">
                      <a className="nav-link nav-link-style" href="/">
                        Home
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link nav-link-style" href="#contact-us">
                        Contact Us
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link nav-link-style" href="#gallery">
                        Gallery
                      </a>
                    </li>
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link nav-link-style dropdown-toggle"
                        href="#"
                        onClick={toggleDropdown}
                      >
                        Our estates
                      </a>
                      <ul className={`dropdown-menu ${isDropdownOpen ? 'show' : ''}`}>
                        {estates.map((estate: any, index) => (
                          <li key={estate.id || index}>
                            <a className="dropdown-item" href={`/estates/${estate.id}`}>
                              {estate.name || 'Estate'}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </li>
                  </ul>
                  <div className="nav-btn-container">
                    <a
                      href="/affiliate-program"
                      className="btn btn-white add-listing-btn nav-link-style become-an-affiliate-btn"
                    >
                      Become an affiliate
                    </a>
                    <a
                      href="/buy-land"
                      className="btn btn-white add-listing-btn nav-link-style buy-now-btn"
                    >
                      Buy Now
                    </a>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;