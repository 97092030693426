import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../helpers/base_url";
import './estate-detail.css'

function EstateDetail() {
  const { id } = useParams();
  const [estate, setEstate] = useState<any>({})
  const [precautionaryTips, setPrecautionaryTips] = useState<any>([])
  useEffect(() => {
    fetch(`${API_BASE_URL}/estate?estate_id=${id}`)
      .then(res => res.json())
      .then(data => {
        setEstate(data?.data);
      })
      .catch(err => console.error('Fetch error:', err));

    // fetch precautionary tips
    fetch(`${API_BASE_URL}/precautionary-tip`)
      .then(res => res.json())
      .then(data => {
        setPrecautionaryTips(data?.data);
      })
      .catch(err => console.error('Fetch error:', err));
  }, []);

  const [activeIndex, setActiveIndex] = useState(0);

  const faqItems = [
    {
      title: "Website and Mobile App Design",
      content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum."
    },
    {
      title: "Website and Mobile App Design",
      content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum."
    },
    {
      title: "Branding and Logo Design",
      content: "Our branding services help establish a unique identity for your business. We create memorable logos and comprehensive brand guidelines that ensure consistency across all your marketing materials."
    },
    {
      title: "Digital Marketing Solutions",
      content: "From SEO to social media management, our digital marketing strategies help you reach your target audience effectively and convert visitors into loyal customers."
    }
  ];

  const toggleAccordion = (index: any) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  // For each property template, maintain a state for the active image index
  const [activeImageIndices, setActiveImageIndices] = useState<any>({});

  // Function to set active image for a specific property
  const setActiveImage = (propertyId: any, index: number) => {
    setActiveImageIndices((prev: any) => ({
      ...prev,
      [propertyId]: index
    }));
  };

  // Function to move to next/previous image
  const navigateImage = (propertyId: any, direction: any) => {
    setActiveImageIndices((prev: any) => {
      const currentIndex = prev[propertyId] || 0;
      const images = estate?.propertyTemplates.find((p: any) => p.id === propertyId)?.images || [];

      if (direction === 'next') {
        return { ...prev, [propertyId]: (currentIndex + 1) % images.length };
      } else {
        return { ...prev, [propertyId]: (currentIndex - 1 + images.length) % images.length };
      }
    });
  };

  return (
    <>
      <div className="full-row pt-0" style={{ marginTop: "9rem" }}>
        <div className="container">
          <div className="row g-5" style={{ alignItems: "center" }}>
            <div className="col-xl-6 order-xl-1">

              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    backgroundColor: "#510000",
                    width: "1rem",
                    height: "11rem",
                    marginRight: "2rem",
                    marginTop: "6rem"
                  }}
                ></div>

                <div>
                  <h3
                    className="text-black header-text">
                    {estate?.name}
                  </h3>

                  <div>
                    <p className="estate-detail-description-text">
                      <div dangerouslySetInnerHTML={{ __html: estate?.description }}></div>
                    </p>

                    <a href={`/buy-estate-land/${estate?.id}`} className="btn estate-detail-btn">
                      Buy Now
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-6 order-xl-2">
              <img
                width="613px"
                height="594px"
                src={estate?.imageUrl}
                className="ls-bg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="full-row pt-0" style={{ marginTop: "2rem" }}>
        <div className="container">
          <div className="row">
            <div className="col-xl-6 order-xl-1 mb-5">
              <iframe style={{
                width: "561px",
                height: "894px"
              }} src={estate?.mapUrl}></iframe>
              <div className="mt-5">
                <h3 className="precautinary-title-text">
                  Precautionary Tip
                </h3>

                {precautionaryTips.map((precautionaryTip: any, index: number) => (
                  <p
                    key={precautionaryTip?.id || index}
                    className="precautinary-item-text"
                  >
                    <span className="mr-5">{index + 1}.</span>{precautionaryTip?.tip}
                  </p>
                ))}
              </div>
            </div>

            {/* {(estate?.propertyTemplates || []).map((propertyTemplate: any, index: number) => (
              <div key={propertyTemplate?.id || index} className="col-xl-6 order-xl-2">
                <div className="property-overview border summary rounded bg-white p-10 mb-50">
                  <div className="row">
                    <div className="col-md-12">
                      <div
                        id="single-property"
                        style={{
                          margin: "0 auto 30px",
                        }}
                      >
                        <div>
                          {" "}
                          <img
                            width="800"
                            height="400"
                            src={propertyTemplate.images[0]?.imageUrl}
                            className="ls-bg"
                            alt=""
                          />{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-auto">
                      <h4 className="estate-detail-property-title-text">
                        <p>
                          {propertyTemplate?.templateName}
                        </p>
                      </h4>
                    </div>
                  </div>
                  <div className="row row-cols-1">
                    <div className="col">
                      <p
                        className="estate-detail-property-description-text"
                        dangerouslySetInnerHTML={{ __html: propertyTemplate?.description }}
                      >
                      </p>
                    </div>
                  </div>

                  <div className="col-12">
                    <hr />
                    <div className="product-offers mt-2">
                      <ul className="product-offers-list">
                        {propertyTemplate?.facilityItems.map((facility: any, index: number) => (
                          <li key={facility.id || index} className="estate-detail-property-facility-item-text">
                            {" "}
                            {facility?.propertyFacility?.name}
                          </li>
                        ))}
                        <li className="estate-detail-property-price">
                          {" "}
                          Asking Price:{" "}
                          <strong>NGN {propertyTemplate?.prices[0]?.price} ( {propertyTemplate?.negotiationStatus?.name})</strong>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <ul className="quick-meta mt-4">
                    <li className="w-auto p-0">
                      <a
                        href={`/buy-land/${propertyTemplate.id}`}
                        className="btn btn-white estate-detail-share-btn"
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <img src="/assets/images/icon/cart-icon.png" />
                        Buy Now
                      </a>
                    </li>

                    <li className="w-auto">
                      <a
                        href="#"
                        className="btn btn-white estate-detail-share-btn"
                        style={{ marginLeft: "1rem", display: 'flex', alignItems: 'center' }}
                      >
                        <img src="/assets/images/icon/share-icon.png" />
                        Share
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            ))} */}

            {(estate?.propertyTemplates || []).map((propertyTemplate: any, index: any) => {
              const activeImageIndex = activeImageIndices[propertyTemplate?.id] || 0;
              const images = propertyTemplate?.images || [];

              return (
                <div key={propertyTemplate?.id || index} className="col-xl-6 order-xl-2">
                  <div className="property-overview border summary rounded bg-white p-3 p-md-10 mb-50">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="relative mb-6">
                          <div className="main-image-container relative">

                            <img
                              src={images[activeImageIndex]?.imageUrl || "/assets/images/placeholder.jpg"}
                              className="w-full h-64 md:h-96 object-cover estate-detail-property-img"
                              alt={`Property ${propertyTemplate?.templateName}`}
                            />
                          </div>

                          <div className="position-relative mt-2 mb-3">
                            {/* Navigation buttons positioned absolutely on left and right sides */}
                            <span
                              onClick={() => navigateImage(propertyTemplate?.id, 'prev')}
                              className="position-absolute top-50 start-0 translate-middle-y p-2 shadow"
                              style={{ zIndex: 10 }}
                              aria-label="Previous image"
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <path d="M15 18l-6-6 6-6" />
                              </svg>
                            </span>

                            {/* Horizontally scrolling thumbnails with padding to leave space for buttons */}
                            <div className="d-flex flex-row overflow-auto mx-4">
                              {images.slice(0, 4).map((image: any, imgIndex: number) => (
                                <div
                                  key={imgIndex}
                                  className={`mx-1 p-2 ${activeImageIndex === imgIndex ? 'border border-primary' : ''}`}
                                  onClick={() => setActiveImage(propertyTemplate?.id, imgIndex)}
                                  style={{ cursor: 'pointer', flexShrink: 0 }}
                                >
                                  <img
                                    src={image?.imageUrl}
                                    className="estate-detail-property-img-thumbnail"
                                    alt={`Thumbnail ${imgIndex + 1}`}
                                  />
                                </div>
                              ))}
                            </div>

                            {/* Right navigation button */}
                            <span
                              onClick={() => navigateImage(propertyTemplate?.id, 'next')}
                              className="position-absolute top-50 end-0 translate-middle-y bg-white p-2 shadow"
                              style={{ zIndex: 10 }}
                              aria-label="Next image"
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <path d="M9 18l6-6-6-6" />
                              </svg>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mb-4">
                      <div className="col-auto">
                        <h4 className="estate-detail-property-title-text">
                          <p>{propertyTemplate?.templateName}</p>
                        </h4>
                      </div>
                    </div>

                    <div className="row row-cols-1">
                      <div className="col">
                        <p
                          className="estate-detail-property-description-text"
                          dangerouslySetInnerHTML={{ __html: propertyTemplate?.description }}
                        ></p>
                      </div>
                    </div>

                    <div className="col-12">
                      <hr />
                      <div className="product-offers mt-2">
                        <ul className="product-offers-list">
                          {propertyTemplate?.facilityItems?.map((facility: any, index: any) => (
                            <li key={facility.id || index} className="estate-detail-property-facility-item-text">
                              {facility?.propertyFacility?.name}
                            </li>
                          ))}
                          <li className="estate-detail-property-price">
                            Asking Price:{" "}
                            <strong>
                              NGN {propertyTemplate?.prices[0]?.price} ({propertyTemplate?.negotiationStatus?.name})
                            </strong>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <ul className="quick-meta mt-4 flex flex-wrap">
                      <li className="w-auto p-0 mr-2 mb-2">
                        <a
                          href={`/buy-land/${propertyTemplate.id}`}
                          className="btn btn-white estate-detail-share-btn flex items-center"
                        >
                          <img src="/assets/images/icon/cart-icon.png" alt="Buy" className="estate-detail-share-btn-icon mr-2" />
                          Buy Now
                        </a>
                      </li>

                      <li className="w-auto mb-2">
                        <a
                          href="#"
                          className="btn btn-white estate-detail-share-btn flex items-center"
                        >
                          <img src="/assets/images/icon/share-icon.png" alt="Share" className="estate-detail-share-btn-icon mr-2" />
                          Share
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="full-row bg-light">
        <div className="container">
          <div className="row lg-w-50 md-w-80 sm-w-100">
            <div className="col mb-5">
              <p
                className="text-start faq-sub-header-text"
              >
                FREQUENTLY ASKED QUESTIONS
              </p>
              <h2
                className="down-line w-100 mx-auto mb-4 text-start w-sm-100 faq-header-text"
              >
                We Understand your <br /> Concerns
              </h2>
              <span
                className="d-table w-50 w-sm-100 text-start faq-description-text"
              >
                Trust Landbankng ltd for your real estate transactions.
                Experience the future of secure and transparent escrow services
              </span>
            </div>

            <div className="bb-accordion ac-single-show accordion-plus-left">
              {faqItems.map((item, index) => (
                <div key={index} className="ac-card bg-white p-4 mt-3 first:mt-0">
                  <a
                    className={`text-dark text-truncate ${activeIndex === index ? 'active' : ''}`}
                    style={{ cursor: 'pointer' }}
                    onClick={() => toggleAccordion(index)}
                  >
                    <div className="flex justify-between items-center">
                      <span className="faq-item-title-icon">{activeIndex === index ? '−' : '+'}</span>
                      <span className="faq-item-title-text">{item.title}</span>
                    </div>
                  </a>
                  <div
                    className={`ac-collapse ${activeIndex === index ? 'show' : ''}`}
                    style={{ display: activeIndex === index ? 'block' : 'none' }}
                  >
                    <p className="faq-item-description-text mt-2">
                      {item.content}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EstateDetail;
