import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './banner.css'

import bgImage1 from '../../imgs/background/cadland-bg-img.png';
import bgImage2 from '../imgs/background/cadland-bg-img2.png';
import bgImage3 from '../imgs/background/cadland-bg-img3.png';

function Banner() {
    // Slider settings
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        fade: true,
    };

    // Carousel data with different backgrounds, titles and descriptions for each slide
    const slides = [
        {
            backgroundImage: bgImage1,
            title: "Your Trusted Partner in Real Estate Acquisition",
            description: "At CADLANDS, we believe that every piece of property represents the potential for a brighter tomorrow. With integrity, innovation, sustainability, and customer-centricity at our core, we're redefining what it means to invest in real estate in Nigeria.",
            buttonText: "Buy Now",
            buttonLink: "/buy-land"
        },
        {
            backgroundImage: bgImage1,
            title: "Your Trusted Partner in Real Estate Verification",
            description: "At CADLANDS, we believe that every piece of property represents the potential for a brighter tomorrow. With integrity, innovation, sustainability, and customer-centricity at our core, we're redefining what it means to invest in real estate in Nigeria.",
            buttonText: "Buy Now",
            buttonLink: "/buy-land"
        },
        {
            backgroundImage: bgImage1,
            title: "Your Trusted Partner in Real Estate Listings",
            description: "At CADLANDS, we believe that every piece of property represents the potential for a brighter tomorrow. With integrity, innovation, sustainability, and customer-centricity at our core, we're redefining what it means to invest in real estate in Nigeria.",
            buttonText: "Buy Now",
            buttonLink: "/buy-land"
        }
    ];
    return (
        <>
            <Slider {...settings}>
                {slides.map((slide, index) => (
                    <div key={index} className="full-row p-0 position-relative vh-100 d-flex align-items-end">
                        <div
                            className="position-absolute top-0 start-0 w-100 h-100"
                            style={{
                                backgroundImage: `url(${slide.backgroundImage})`,
                                backgroundPosition: "center center",
                                backgroundSize: "cover",
                                zIndex: 0,
                            }}
                        ></div>

                        {/* Overlay */}
                        <div
                            className="position-absolute top-0 start-0 w-100 h-100"
                            style={{
                                backgroundColor: "rgba(0, 0, 0, 0.7)",
                                zIndex: 1,
                            }}
                        ></div>

                        <div
                            className="container position-relative"
                            style={{ zIndex: 2 }}
                        >
                            <div className="row">
                                <div className="col-12 col-md-10 w-60 w-md-50 w-lg-50 w-sm-100">
                                    <div className="banner-search sm-p-0 pt-5 pb-3">
                                        <h2 className="text-white title-text">
                                            {slide.title}
                                        </h2>
                                    </div>
                                </div>
                            </div>

                            <div className="full-row sub-title-text-container">
                                <div className="container">
                                    <div className="row">
                                        <div className="col mb-5">
                                            <span className="d-block w-50 w-md-75 w-lg-70 w-sm-100 sub-title-text">
                                                {slide.description}
                                            </span>

                                            <a href={slide.buttonLink}
                                                className="btn btn-white d-inline-block buy-now-btn"
                                            >
                                                {slide.buttonText}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider >

            <div className="full-row" style={{ paddingTop: "6rem" }}>
                <div className="container">
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-4 g-4 g-lg-9 justify-content-center">
                        {[
                            { number: "3+", label: "Our Estates" },
                            { number: "5k+", label: "Properties Sold" },
                            { number: "500+", label: "Trusted Clients" },
                            { number: "10+", label: "Choice Locations" }
                        ].map((stat, index) => (
                            <div
                                key={index}
                                className="col"
                                style={{
                                    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
                                }}
                            >
                                <a
                                    href="#"
                                    className="text-center d-flex flex-column align-items-center p-3 p-md-4 bg-white transition text-general h-100"
                                >
                                    <h1
                                        className="d-block text-secondary mb-2"
                                        style={{
                                            fontFamily: 'Poppins, sans-serif',
                                            fontWeight: "700",
                                            fontSize: "clamp(28px, 8vw, 38px)", // Responsive font size
                                            lineHeight: "1.3", // Relative line height
                                            letterSpacing: "-0.02em",
                                            textAlign: "center",
                                            color: "#000",
                                        }}
                                    >
                                        {stat.number}
                                    </h1>
                                    <p
                                        style={{
                                            fontFamily: "Mulish, sans-serif",
                                            fontWeight: "400",
                                            fontSize: "clamp(14px, 4vw, 16px)", // Responsive font size
                                            lineHeight: "1.3", // Relative line height
                                            textAlign: "center",
                                            color: "#000",
                                        }}
                                    >
                                        {stat.label}
                                    </p>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Banner